<template>
    <ais-instant-search 
        :search-client="searchClient"
        index-name="bluenoteIndex"
        :stalled-search-delay="100"
		:future="{ preserveSharedStateOnUnmount: true }">
    <div class="search">
        <ais-search-box
            v-model="searchQuery"
            autofocus
            submit-title="Submit the query"
            reset-title="Remove the query">
            <template v-slot="{ currentRefinement, refine }">
                <form class="ais-SearchBox-form" @submit.prevent="filterSearch($event, refine, currentRefinement)">
                    <input
						class="ais-SearchBox-input"
						id="ais-SearchBox-input"
						type="search"
						:value="currentRefinement"
						show-loading-indicator
						placeholder="Search anything..."
						@input="filterSearch($event, refine, currentRefinement)"
						:autofocus="true">
					<div class="resetBtn">
						<span @click="searchQuery = ' '" class="material-icons resetIcon">cancel</span>
					</div>
				</form>
            </template>
        </ais-search-box>
		<ais-sort-by
			name="ais-SortBy-select"
			:class-names="{ 'ais-SortBy-option': 'aOption'}"
            :items="[
              	{ value: 'bluenoteIndex', label: 'Select Sorting' },
                { value: 'bluenoteIndexAlbum', label: 'Sort by Album' },
                { value: 'bluenoteIndex', label: 'Sort by Artist' },
                { value: 'bluenoteIndexRecorded', label: 'Sort by Session Date' },
                { value: 'bluenoteIndexRating', label: 'Sort by Avg Rating' },
                { value: 'bluenoteIndexRelease', label: 'Sort by Release Date' },
                { value: 'bluenoteIndexYear', label: 'Sort by Year' } ]">
        </ais-sort-by>
		<ais-menu-select attribute="Series">
            <template v-slot:defaultOption>Select Series</template>
        </ais-menu-select>
        <ais-configure :enable-rules.camel="false" :hits-per-page.camel="12" >
            <template v-slot="{ searchParameters, refine }" >
                <select class="ais-MenuSelect-select" id="ais-MenuSelect-select"
                v-model="searchFilter"
                @change="filterAlbums(searchParameters, refine, user)">
                    <option value="">Select Filter</option>
                    <option value="collected">Collected by {{ user.name }}</option>
                    <option value="!collected">Not in {{ user.name }} collection</option>
                    <option value="favorites">Favorites by {{ user.name }}</option>
                    <option value="!favorites">Not in {{ user.name }} favorites</option>
                    <option value="reviews">Reviewed by {{ user.name }}</option>
                    <option value="!reviews">Not reviewed by {{ user.name }} </option>
                </select>
            </template>
        </ais-configure>
		<ais-hits-per-page
			:items="[
				{ label: '8 hits per page', value: 8 },
				{ label: '12 hits per page', value: 12, default: true },
				{ label: '16 hits per page', value: 16 },
				{ label: '24 hits per page', value: 24 },
				{ label: '36 hits per page', value: 36 },
				{ label: '48 hits per page', value: 48 } ]"
		/>
		<ais-stats>
			<template v-slot="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }">
				<strong>{{ nbHits }}</strong> hits retrieved in {{ processingTimeMS }}ms for <q>{{ query }}</q><br>
				Page {{ page + 1 }} of {{ nbPages }} with {{ hitsPerPage }} hits per page <br>
			</template>
		</ais-stats>		
    </div>

    <ais-hits class="home">
        <template v-slot:item="{ item: album }" >
            <div class="album-cover">
				<router-link :to="{ name: 'Album', params: { id: album.objectID } }">
					<img :src="require('@/assets/covers/' + album.objectID + '.jpg')" 
                    :alt="album.Album" loading="lazy"
					class="bilde">
				<br>
				</router-link>
                <div class="icons">
					<!-- User Favorites -->
                    <div class="tooltip" v-if="user.favorites">
						<span :class="{ icon: true, iconFavorite: user.favorites.includes(album.objectID)}">
							<span @click="userFavorite(user, album.objectID)" class="material-icons">favorite</span>
							<span v-if="user.favorites.includes(album.objectID)" class="tooltiptext">Remove Album from your Favorite</span>
							<span v-if="!user.favorites.includes(album.objectID)" class="tooltiptext">Add Album to your Favorite</span>
						</span>
                    </div>
					<!-- User Playlist -->
					<div class="tooltip" v-if="user.id">
                        <span :class="{ icon: true, iconPlaylist: true }" id="iconPlaylist">
                            <span @click="addToPlaylist(album, user.id)" class="material-icons">playlist_add_check_circle</span>
                            <span class="tooltiptext">Add Album to your Playlist</span>
                        </span>
                    </div>
                    <!-- Modal Collection -->
                    <div class="tooltip" v-if="user.collected">
                        <span :class="{ icon: true, iconCollect: user.collected.includes(album.objectID)}">
                            <span @click="openCollectedModal(album)" class="material-icons">album</span>
                            <span v-if="user.collected.includes(album.objectID)" class="tooltiptext">Edit your Collected Album</span>
                            <span v-if="!user.collected.includes(album.objectID)" class="tooltiptext">Add Album to your Collection</span>
                        </span>
                    </div>
					<!-- Modal Review -->
					<div class="tooltip" v-if="user.reviews"> 
                        <span :class="{ icon: true, iconReview: user.reviews.includes(album.objectID)}">
                            <span @click="openReviewModal(album)" class="material-icons">reviews</span>
							<span v-if="user.reviews.includes(album.objectID)" class="tooltiptext">Edit your Album Review</span>
                            <span v-if="!user.reviews.includes(album.objectID)" class="tooltiptext">Add your Album Review</span>
                        </span>
                    </div>
					<!-- ADMIN ONLY: Edit Album -->
                    <div class="tooltip" v-if="user && user.admin">
						<span>
							<router-link :to="{ name: 'EditAlbum', params: { id: album.objectID } }">
								<span class="material-icons icon iconEdit">edit</span>
                                <span class="tooltiptext">Edit Album</span>
                            </router-link>
                        </span>
                    </div>
					<!-- No user needed for these icons -->
					<!-- Apple Music -->
					<div class="tooltip">
						<span :class="{ icon: true, iconPlay: album.showPlayer }">
							<span @click="openPlayer(album, $event)" class="material-icons">play_circle</span>
							<span class="tooltiptext">Open Album Player</span>
						</span>
					</div>
					<!-- Album -->
                    <div class="tooltip">
						<span :class="{ icon: true, iconMore: !album.showInfo, iconMoreOpen: album.showInfo }" >
							<span @click="openInfo(album, $event)" class="material-icons">expand_circle_down</span>
							<span v-if="!album.showInfo" class="tooltiptext">Open Album Details</span>
							<span v-else class="tooltiptext">Close Album Details</span>
                        </span>
                    </div>
					<!-- Modal -->
                    <div class="tooltip">
                        <span :class="{ icon: true, iconInfo: true}">
                            <span @click="openAlbumModal(album)" class="material-icons">info</span>
                            <span class="tooltiptext">Open Album Window</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="albumInfo">
				<div class="albumBox">
					<section>
						<h2 class="subheading">{{album.Artist}}:</h2>
						<h2 class="subheading">{{album.Album}} ({{album.Year}})</h2>
						<star-rating
							v-if="album.ratingAverage"
							class="avgReview"
							v-bind:star-size="25"
							:increment="0.01"
							:glow="2" 
							glow-color="#ffd055"
							text-class="avg-star-text"
							:padding="0"
							:animate="false"
							:read-only="true"
							:show-rating="true"
							inactive-color="#212121"
							v-model:rating="album.ratingAverage">
						</star-rating>
					</section>
					<section>
						<h3 class="subheading">Album info</h3>
						<div class="subcontent">
							<p v-if="album.Series">Series: {{album.Series}}</p>
							<p v-if="album.Label">{{album.Label}}: {{album.CatalogID}}</p>
							<p v-if="album.Discogs" >DiscogsID: <a :href="album.Discogs" target="blank">{{album.DiscogsID}}</a></p>
							<p>albumID: {{album.objectID}}</p>
						</div>
					</section>							
					<section>
						<h3 class="subheading">Dates</h3>
						<div class="subcontent">
							<p v-if="album.Recorded1">
								<span v-if="album.Recorded1">Recorded: {{unixtimeToLongdate(album.Recorded1)}}</span>
								<span v-if="album.Recorded2">, {{unixtimeToLongdate(album.Recorded2)}}</span>
								<span v-if="album.Recorded3">, {{unixtimeToLongdate(album.Recorded3)}}</span>
								<span v-if="album.Recorded4">, {{unixtimeToLongdate(album.Recorded4)}}</span>
								<span v-if="album.Recorded5">, {{unixtimeToLongdate(album.Recorded5)}}</span>
							</p>
							<p v-if="album.RecordedDates">RecordedDates: {{album.Recorded1}}</p>
							<p v-if="album.Release">Release: {{unixtimeToLongdate(album.Release)}}</p>
							<p v-if="user.admin && album.Collected">Collected: {{unixtimeToLongdate(album.Collected)}}</p>
							<p v-if="album.created">Created: {{unixtimeToLongdate(album.created)}}</p>
							<p v-if="album.updated">Update: {{unixtimeToLongdate(album.updated)}}</p>
						</div>
					</section>
					<section>
						<h3 class="subheading">Tracks</h3>
						<div class="subcontent">
							<p v-for="(song, index) in album.Songs" :key="song">
								{{index+1}} {{song}}
							</p>
						</div>
					</section>
					<section>
						<h3 class="subheading">Links</h3>
						<div class="subcontent">
							<h3><a v-if="album.Review" :href="album.Review" target="blank">Allmusic</a></h3>
							<h3><a v-if="album.Amazon" :href="album.Amazon" target="blank">Amazon</a></h3>
							<h3><a v-if="album.Discogs" :href="album.Discogs" target="blank">Discogs</a></h3>
							<h3><a v-if="album.Play" :href="album.Play" target="blank">Release Links</a></h3>
							<h3><a v-if="album.SeriesLink" :href="album.SeriesLink" target="blank">Series</a></h3>
							<h3><a v-if="album.Wiki" :href="album.Wiki" target="blank">Wikipedia</a></h3>
						</div>
					</section>
					<section v-if="false"> 
						<!-- userCollection ref? NOT FINISHED!!!!!! -->
						<h3 class="subheading" v-if="user.collected.includes(album.objectID)">Collection</h3>
						<div v-if="album.objectID == colAlbum.albumId && showCollection" class="subcontent">
							<p v-if="colAlbum.Butikk">Butikk: {{colAlbum.Butikk}}</p>
							<p v-if="colAlbum.Ordered">Ordered: {{timestampToLongdate(colAlbum.Ordered)}}</p>
							<p v-if="colAlbum.Collected">Collected: {{timestampToLongdate(colAlbum.Collected)}}</p>
							<p v-if="colAlbum.Sum">Sum: {{colAlbum.Sum}}</p>
							<p v-if="colAlbum.updated">Update: {{timestampToTimedate(colAlbum.updated)}}</p>
						</div>
					</section>
					<section>
						<h3 class="subheading">New Musicians</h3>
						<div class="musicianInfo subcontent">
							<template v-for="musician in album.musiciansArray" :key="musician.name">
								<p>{{ musician.name }}:
									<template v-for="(instrument, index) in musician.instruments" :key="instrument">
										<span v-if="index < musician.instruments.length-1">{{ instrument }}, </span>
										<span v-else>{{ instrument }}</span>
									</template>
								</p>
							</template>
						</div>
					</section>
					<section>
						<h3 class="subheading">Musicians</h3>
						<div class="musicianInfo subcontent">
							<ais-refinement-list attribute="Musicians" >
								<template v-slot="{ refine }">
									<div class="musicians">
										<ul v-if="album.musicianSaxAlto" id="SaxAlto">
											<li v-for="SaxAlto in makeArray(album.musicianSaxAlto)" :key="SaxAlto">
												Alto Saxophone <span @click="filterSearch($event, refine)">{{SaxAlto}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianSaxBaryton" id="SaxBaryton">
											<li v-for="SaxBaryton in makeArray(album.musicianSaxBaryton)" :key="SaxBaryton">
												Baryton Saxophone <span @click="filterSearch($event, refine)">{{SaxBaryton}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianBass" id="Bass">
											<li v-for="Bass in makeArray(album.musicianBass)" :key="Bass">
												Bass <span @click="filterSearch($event, refine)">{{Bass}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianClarinet" id="Clarinet">
											<li v-for="Clarinet in makeArray(album.musicianClarinet)" :key="Clarinet">
												Clarinet <span @click="filterSearch($event, refine)">{{Clarinet}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianConga" id="Conga">
											<li v-for="Conga in makeArray(album.musicianConga)" :key="Conga">
												Conga <span @click="filterSearch($event, refine)">{{Conga}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianCornette" id="Cornette">
											<li v-for="Cornette in makeArray(album.musicianCornette)" :key="Cornette">
												Cornette <span @click="filterSearch($event, refine)">{{Cornette}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianDrums" id="Drums">
											<li v-for="Drums in makeArray(album.musicianDrums)" :key="Drums">
												Drums <span @click="filterSearch($event, refine)">{{Drums}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianEuphonium" id="Euphonium">
											<li v-for="Euphonium in makeArray(album.musicianEuphonium)" :key="Euphonium">
												Euphonium <span @click="filterSearch($event, refine)">{{Euphonium}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianFlute" id="Flute">
											<li v-for="Flute in makeArray(album.musicianFlute)" :key="Flute">
												Flute <span @click="filterSearch($event, refine)">{{Flute}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianGuitar" id="Guitar">
											<li v-for="Guitar in makeArray(album.musicianGuitar)" :key="Guitar">
												Guitar <span @click="filterSearch($event, refine)">{{Guitar}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianHarp" id="Harp">
											<li v-for="Harp in makeArray(album.musicianHarp)" :key="Harp">
												Harp <span @click="filterSearch($event, refine)">{{Harp}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianHorns" id="Horns">
											<li v-for="Horns in makeArray(album.musicianHorns)" :key="Horns">
												Horns <span @click="filterSearch($event, refine)">{{Horns}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianOrgan" id="Organ">
											<li v-for="Organ in makeArray(album.musicianOrgan)" :key="Organ">
												Organ <span @click="filterSearch($event, refine)">{{Organ}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianPercussion" id="Percussion">
											<li v-for="Percussion in makeArray(album.musicianPercussion)" :key="Percussion">
												Percussion <span @click="filterSearch($event, refine)">{{Percussion}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianPiano" id="Piano">
											<li v-for="Piano in makeArray(album.musicianPiano)" :key="Piano">
												Piano <span @click="filterSearch($event, refine)">{{Piano}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianSitar" id="Sitar">
											<li v-for="Sitar in makeArray(album.musicianSitar)" :key="Sitar">
												Sitar <span @click="filterSearch($event, refine)">{{Sitar}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianSaxSoprano" id="SaxSoprano">
											<li v-for="SaxSoprano in makeArray(album.musicianSaxSoprano)" :key="SaxSoprano">
												Soprano Saxophone <span @click="filterSearch($event, refine)">{{SaxSoprano}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianString" id="String">
											<li v-for="String in makeArray(album.musicianString)" :key="String">
												Strings <span @click="filterSearch($event, refine)">{{String}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianSaxTenor" id="SaxTenor">
											<li v-for="SaxTenor in makeArray(album.musicianSaxTenor)" :key="SaxTenor">
												Tenor Saxophone <span @click="filterSearch($event, refine)">{{SaxTenor}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianTrombone" id="Trombone">
											<li v-for="Trombone in makeArray(album.musicianTrombone)" :key="Trombone">
												Trombone <span @click="filterSearch($event, refine)">{{Trombone}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianTrumpet" id="Trumpet">
											<li v-for="Trumpet in makeArray(album.musicianTrumpet)" :key="Trumpet">
												Trumpet <span @click="filterSearch($event, refine)">{{Trumpet}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianTuba" id="Tuba">
											<li v-for="Tuba in makeArray(album.musicianTuba)" :key="Tuba">
												Tuba <span @click="filterSearch($event, refine)">{{Tuba}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianVibraphone" id="Vibraphone">
											<li v-for="Vibraphone in makeArray(album.musicianVibraphone)" :key="Vibraphone">
												Vibraphone <span @click="filterSearch($event, refine)">{{Vibraphone}}</span>
											</li>
										</ul>
										<ul v-if="album.musicianVocal" id="Vocal">
											<li v-for="Vocal in makeArray(album.musicianVocal)" :key="Vocal">
												Vocal <span @click="filterSearch($event, refine)">{{Vocal}}</span>
											</li>
										</ul>
									</div>
								</template>
							</ais-refinement-list>
						</div>
					</section>
				</div>
            </div>
			<!-- END: albumInfo  -->
            <div v-if="album.showPlayer" class="apple-music" >
                <iframe :src="album.Apple" loading="lazy" allow="autoplay *; encrypted-media *;" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" 
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation">
                    {{album.Apple}}
                </iframe>
            </div>
        </template>
    </ais-hits>
    <ais-pagination 
        :total-pages="100"
        :padding="2"
        :show-first="true"
        :show-previous="false"
        :show-next="false"
        :show-last="true" />
    </ais-instant-search>
	<AlbumModal
		v-if="showAlbumModal" 
		@close="showAlbumModal = false"
		:album="activeAlbum" 
		:user="user"
	/>
	<AddReview
		v-if="showAddReview"
		@close="showAddReview = false"
		@new-review ="(data, user) => newReview(data, user)"
		@update-review ="(data, user) => updateReview(data, user)"
		@delete-review ="(data, user) => deleteReview(data, user)"
		:album="activeAlbum"
		:user="user"
	/>
	<AddCollection
		v-if="showAddCollection"
		@close="showAddCollection = false"
		@new-collected ="(data, user) => newCollection(data, user)"
		@update-collected ="(data, user) => updateCollection(data, user)"
		@delete-collected ="(data, user) => deleteCollection(data, user)"
		:album="activeAlbum"
		:user="user"
	/>
</template>

<script>
import { inject, ref, watch  } from 'vue'
import StarRating from 'vue-star-rating'
import AlbumModal from '@/components/modals/AlbumModal.vue'
import AddReview from '@/components/modals/AddReview.vue';
import AddCollection from '@/components/modals/AddCollection.vue';
// Algolia imports
import algoliasearch from 'algoliasearch'
import { AisSearchBox, AisSortBy, AisMenuSelect, AisConfigure, AisRefinementList, AisHitsPerPage } from 'vue-instantsearch/vue3/es/index'
import instantsearch from 'instantsearch.js';
// Sunes composables
import { unixtimeToLongdate } from '@/composables/timestampConverter'
import { formatDistanceToNow } from 'date-fns'
import { userFavorite } from '@/composables/userFavorite'
import { makeArray } from '@/composables/makeArray'
import { addToPlaylist } from '@/composables/addToPlaylist'
import { getMusicians } from '@/composables/getMusicians'
// Algolia CSS
import '@/assets/css/satellite.css'
import { unrefElement } from '@vueuse/core';
export default {
    name: 'Complete',
	props: ['user'],
	emits: ['update-user','store-data'],
    components: { AisSearchBox, AisSortBy, AisMenuSelect, AisConfigure, AisRefinementList, AlbumModal, AddReview, AddCollection, StarRating, AisHitsPerPage },
    setup(props, context){
		// Algolia API
        const searchClient = algoliasearch('EY8MD4PJ8Z','270dd45aec4715743d58ddd99fde969e')
        const index = searchClient.initIndex('bluenoteIndex')
        const search = instantsearch({
			indexName: 'bluenoteIndex',
            searchClient,
			future: {
				preserveSharedStateOnUnmount: false,
			},
        })
		// ALGOLIA Funksjoner
		const searchFilter = ref('')
        const searchQuery = ref()


		

		// Toggle Info/Player in albumBox
		const showPlayer = ref(false)
		const showInfo = ref(false)
		
		// // User Arrays
		// const userCollected = ref([])
        // const userReviews = ref([])
		
		// Toggle Modals
        const showAlbumModal = ref(false)
        const showAddReview = ref(false)
		const showAddCollection = ref(false)
		
		// Album Prop
		const activeAlbum = ref()
		
		const musicians = ref([])
		
		
		const fetchMusicians = async (album) => {
			musicians.value = await getMusicians(album.value)
			console.log('%c musicians', 'color:rgb(0, 153, 156);font-size:1.2em', musicians)
		}
		
		const openPlayer = (album, event) => {
			album.showInfo = false
			album.showPlayer = true
			let grandparent = event.target.parentElement.parentElement.parentElement.parentElement.parentElement
			let element = grandparent.querySelector('.albumInfo')
			element.classList.remove('open')
		}
		const openInfo = async (album, event) => {
			album.showPlayer = false
			album.musiciansArray = await getMusicians(album)
			console.log('%c album.musiciansArray', 'color:rgb(124, 107, 233);font-size:1.2em', album.musiciansArray)
			album.showInfo = !album.showInfo
			let grandparent = event.target.parentElement.parentElement.parentElement.parentElement.parentElement
			let element = grandparent.querySelector('.albumInfo')
			element.classList.toggle('open')
        }
		const openAlbumModal = (album) => {
			showAlbumModal.value = true
			album.id = album.objectID
			activeAlbum.value = album
		}
		const openReviewModal = ( album ) => {
			showAddReview.value = true
			album.id = album.objectID
			activeAlbum.value = album
		}
		const openCollectedModal = ( album ) => {
			showAddCollection.value = true
			album.id = album.objectID
			activeAlbum.value = album
		}
		// User Reviews
		const newReview = (review, user) => {
			console.log('%c Received review from newReview emit', 'color:lime;font-size:1.2em', review, user)
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const updateReview = (review, user) => {
			console.log('%c Received review from updateReview emit', 'color:orange;font-size:1.2em', review, user)
			// Update global user: Emit review to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const deleteReview = (review, user) => {
			console.log('%c Received data from deleteReview emit', 'color:red;font-size:1.2em', review, user)
			// Update global user: Emit data to masterparent (App.vue)
			context.emit('update-user', user)
		}
		// User Collection
		const newCollection = (collected, user) => {
			console.log('%c Received collected from newCollection emit', 'color:lime;font-size:1.2em', collected, user)
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const updateCollection = (collected, user) => {
			console.log('%c Received collected from updateCollection emit', 'color:orange;font-size:1.2em', collected, user)
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const deleteCollection = (collected, user) => {
			console.log('%c Received data from deleteCollection emit', 'color:red;font-size:1.2em', collected, user)
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}

		// ALGOLIA SEARCH
		// ALGOLIA FILTER: Handle Click on musician
        const filterSearch = (event, refine, currentRefinement) => {
            // If Click on musician
            event.preventDefault();
            if(event.type === 'click'){
                searchQuery.value = event.target.innerText
                refine(event.currentTarget.innerText)
            }
            // If Search input
            if(event.type === 'input'){
                currentRefinement = event.currentTarget.value
                searchQuery.value = currentRefinement
                refine(event.currentTarget.value)
            }
        }
		// ALGOLIA FILTER: Select Meny (class="ais-MenuSelect-select")
        const filterAlbums = (searchParameters, refine, user) => {
            let filterString = ''
            if( searchFilter.value === 'favorites'){
                for (let i = 0; i < user.favorites.length; i++) {
                    if(i < (user.favorites.length - 1)){
                        filterString += `objectID:'${user.favorites[i]}' OR `
                    } else{
                        filterString += `objectID:'${user.favorites[i]}'`
                    }
                }}
            if( searchFilter.value === 'collected'){
                for (let i = 0; i < user.collected.length; i++) {
                    if(i < (user.collected.length - 1)){
                        filterString += `objectID:'${user.collected[i]}' OR `
                    } else{
                        filterString += `objectID:'${user.collected[i]}'`
                    }
                }}
            if( searchFilter.value === '!favorites'){
                for (let i = 0; i < user.favorites.length; i++) {
                    if(i < (user.favorites.length - 1)){
                        filterString += `NOT objectID:'${user.favorites[i]}' AND `
                    } else{
                        filterString += `NOT objectID:'${user.favorites[i]}'`
                    }
            }}
            if( searchFilter.value === '!collected'){
                for (let i = 0; i < user.collected.length; i++) {
                    if(i < (user.collected.length - 1)){
                        filterString += `NOT objectID:'${user.collected[i]}' AND `
                    } else{
                        filterString += `NOT objectID:'${user.collected[i]}'`
                    }
                }}
			if( searchFilter.value === 'reviews'){
                for (let i = 0; i < user.reviews.length; i++) {
                    if(i < (user.reviews.length - 1)){
                        filterString += `objectID:'${user.reviews[i]}' OR `
                    } else{
                        filterString += `objectID:'${user.reviews[i]}'`
                    }
                }}
            if( searchFilter.value === '!reviews'){
                for (let i = 0; i < user.reviews.length; i++) {
                    if(i < (user.reviews.length - 1)){
                        filterString += `NOT objectID:'${user.reviews[i]}' AND `
                    } else{
                        filterString += `NOT objectID:'${user.reviews[i]}'`
                    }
                }}
            refine({
                ...searchParameters,
                enableRules: !searchParameters.enableRules,
                filters: filterString,
            })

		const injectQuery = inject('searchQuery', 'searchQuery')
		// searchQuery.value = injectQuery
		let newQ = unrefElement(injectQuery)
		searchQuery.value = newQ

}
		// Watch (wait) for update from App user, then =>
		const waitForUser = watch(()=> props.user, (fetchedUser) => {
			console.log('%c fetchedUser@Home', 'color:rgb(48, 150, 172);font-size:1.2em', props.user)
			
        })
		// const waitForQ = watch(()=> injectQuery, (query) => {
		// 	console.log('query: ', query)
		// 	searchQuery.value = query
		// })

        return { filterAlbums, searchQuery, filterSearch, searchClient, index, search, searchFilter, 
			makeArray, userFavorite, addToPlaylist, unixtimeToLongdate, formatDistanceToNow, getMusicians,
			activeAlbum, 
			openAlbumModal, showAlbumModal, openInfo, showInfo, openPlayer, showPlayer, 
			openReviewModal, showAddReview, newReview, updateReview, deleteReview, 
			openCollectedModal, showAddCollection, newCollection, updateCollection, deleteCollection }
    }
}
</script>

<style>
@media (prefers-color-scheme: dark) {
	body {
		color: #eee;
		background: linear-gradient(300deg, rgb(36, 45, 57) 11.2%, rgb(16, 37, 60) 51.2%, rgb(7, 7, 7) 98.6%);
	}
	a {
		color: rgb(101, 68, 210);
	}
	.ais-Hits {
		background: #20202000;
	}
	li.ais-Hits-item {
		color: #eee;
		background: radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%);
	}
	.albumBox{
		color: #eee;
	}
}
@media (prefers-color-scheme: light) {
	li.ais-Hits-item {
		color: #121212;
		background: linear-gradient(120deg, rgba(34, 85, 169, 0.5) -20%, rgba(139, 139, 149, 0.5) 51.2%, rgba(34, 85, 169, 0.5) 120%);
		box-shadow: 0 5px 7px #111;
	}
	.modal h2 {
		color: #eee;
	}
}

.albumBox section {
	margin-top: 25px;
}
/* Animation of Open / Close albumInfo */
.albumInfo {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.6s ease-in-out;
}
.albumInfo.open {
	grid-template-rows: 1fr;
}
.albumBox {
	overflow: hidden;
	line-break: strict;
	line-height: 1.2em;
	position: relative;
}
.album-cover {
    display: block;
}
.album-cover img {
	width: 100px;
    cursor: pointer;
}
.album-cover img:last-child {
    margin: 0;
}

/* ALGOLIA */
.ais-Stats {
	padding: 0 10px;
	background: #8888881a;
	border-radius: 5px;
}
.ais-InstantSearch {
    margin-top: 20px;
}
/* SearchBox */
.search {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
}
.ais-SearchBox {
    width: 100%;
}
.ais-SearchBox-form {
    background-color: #8b8b8b00;
    width: 100%;
	position: relative;
	display: inline-block;
}
.ais-SearchBox-input {
    border-radius: 5px;
    font-size: 1.6em;
	font-weight: 700;
    display: inline-block;
	height: 40px;
	width: 100%;
}
.ais-SearchBox-input::placeholder  {
  color:#4a4a4a;
  font-size: .8em;
}
.ais-SearchBox-reset  {
	padding: 2px;
	overflow: hidden;
	font: inherit;
	line-height: normal;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border-radius: 50%;
	display: flex;
	fill: #212121;
	height: 20px;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 20%;
	width: 20px;
}
.resetBtn {
	position: absolute;
	top: 2px;
	right: 0;
	border: none; 
	background-color: transparent; 
	cursor: pointer; 
}
/* Hits */
.ais-Hits {
	margin-top: 40px;
}
.ais-Hits ol {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100%;
}
.ais-Hits-list  {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	width: 100%;
}
.ais-Hits-item {
    width: 100%;
    align-items: baseline;
    padding: 15px;
	height: auto;
	transition: width 2s, height 4s;
}
.ais-Hits-item img {
    width: 100%;
}
[class^=ais-] {
  	box-sizing: border-box;
}
/* Pagination */
.ais-Pagination {
    margin-left: 20px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 1050px;
    justify-items: center;
}
.ais-Pagination-list {
    grid-column-start: 2;
}

@media only screen and (max-width: 1450px) {
	.ais-Hits ol {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .search {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media only screen and (max-width: 1090px) {
	.ais-Hits ol {
        grid-template-columns: 1fr 1fr;
    }
    .search {
        grid-template-columns: 1fr 1fr;
    }
    .ais-Pagination {
        grid-template-columns: 1fr;
        width: 680px;
    }
    .ais-Pagination-list {
        grid-column-start: 1;
    }
}
@media only screen and (max-width: 720px) {
	.ais-Hits ol {
        grid-template-columns: 1fr;
    }
    .search {
        grid-template-columns: 1fr;
    }
    .ais-Pagination {
        width: 330px;
    }
}
</style>