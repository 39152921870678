<template>
   <Navbar
		:authUser="authUser"
		:user="appUser"
   		@user-login="showLogin = true"
   		@user-logout="userLogout"
   /> 
   <ModalLogin
		v-if="showLogin"
		@close="showLogin = false"
	/>
	<div class="container">
		<router-view
			@update-user="(userdata) => updateUserApp(userdata)"
			@store-data="(artistdata) => updateAppStorage(artistdata)"
			:user="appUser" 
			v-if="appUser"
		/>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import ModalLogin from '@/components/modals/ModalLogin.vue'

import { ref, provide, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// User imports
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from './firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export default {
    components: { Navbar, ModalLogin },
	setup(){
		// const authUser = ref({...auth.currentUser})
		const authUser = ref(auth.currentUser)
		const appUser = ref({})
		const artistStore = ref([])
		const userCollected = ref([])
		const userFavorites = ref([])
		const userReviews = ref([])
		const userProps = ref(null)
		// New test of login
		const showLogin = ref(false)
		const router = useRouter()
		const artistConfig = ref({})
		
		// Auth Changes
		onAuthStateChanged(auth, (_authUser) => {
			// console.log('Current authUser in App.Vue ', _authUser)
			authUser.value = _authUser
            if(_authUser){
				getUser(authUser.value.uid)
            }
        })
		// New test of login
		const userLogout = () => {
			console.log('Start signing out...')
            signOut(auth)
			router.push({name: 'Home'})
			// showLogin.value = false
			showLogin.value = true
			// Reset user
			appUser.value = {}
            console.log('Signed Out in App.Vue')
        }

		const updateUserApp = (userdata) => {
			appUser.value = userdata
			console.log('%c App Updated User ', 'color:greenyellow;font-size:1.4em', appUser)
		}
		const updateAppStorage = (artistdata) => {
			artistStore.value = artistdata
			console.log('%c App Updated Store ', 'color:greenyellow;font-size:1.4em', artistStore)
		}
		
		// Get User Data
		const getUser = async (uid) => {
            const userRef = doc(db, 'users', uid)
            const docSnap = await getDoc(userRef)
            if (docSnap.exists()) {
				const data = {...docSnap.data(), id: docSnap.id }
				if(data.password){delete data.password} //remove Password from user obj
				// console.log('%c App User fetched', 'color:#a5c800', data)
				// Update user provided data
                appUser.value = data
            } else { console.log("%c No user document found!", 'color:red;font-size:1.2em') }
        }

		const getArtistConfig = async () => {
			const docRef = doc(db, 'config', "allArtists")
			const docSnap = await getDoc(docRef)
			if (docSnap.exists()){
				artistConfig.value.artistNames = docSnap.data().artistName
				artistConfig.value.artistAlias = docSnap.data().artistAlias
				artistConfig.value.artistIDs = docSnap.data().artistID
			}
		}
		
		const toggleDarkmode = () => {
			// Check for dark mode preference at the OS level
			const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
			// Get the user's theme preference from local storage, if it's available
			const currentTheme = localStorage.getItem("theme");
			// If the OS is set to dark mode...
			if (prefersDarkScheme.matches) {
				// ...then apply the .light-theme class to override those styles
				document.body.classList.toggle("light-theme");
				// Otherwise...
			} else {
				// ...apply the .dark-theme class to override the default light styles
				document.body.classList.toggle("dark-theme");
			}
		 
		}

		// Provides
		provide('appUser', appUser)
		provide('artistConfig', artistConfig)
		provide('artistStore', artistStore)
		console.log('%c provided appUser ', 'color:lime;font-size:1.2em', appUser)

		onMounted(()=> {
			getArtistConfig()
			// console.log('APP.Vue USER', user)
		})

		return { authUser, appUser, userCollected, userFavorites, userReviews, userProps,
			getUser, updateUserApp, userLogout, showLogin, router, artistConfig, getArtistConfig, 
			artistStore, updateAppStorage
		 }
	}
}
</script>

<style>
/* Custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
/* base styles  */
* {
    margin: 0;
    padding: 0;
	box-sizing: border-box;
}
body {
    font-family: 'Nunito', sans-serif;
    background: #dbdbdb;
    background: radial-gradient(circle at 1.3% 2.8%, rgb(239, 249, 249) 0%, rgb(182, 199, 226) 100.2%);
	background: linear-gradient(300deg, rgb(141, 165, 196) 11.2%, rgb(137, 164, 199) 65%, rgb(205, 213, 224) 100.2%);
    overflow-x: hidden;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Layout */
.container {
	max-width: 1800px;
	margin: 0 auto;
	overflow-x: hidden;
	padding: 10px;
	min-height: 95vh;
	/* color: #a5a800; */
}
.container h1 {
    padding-bottom: 10px;
    text-align: center;
}
.home {
    border-radius: 10px;
}
a {
    text-decoration: none;
	color: rgb(52, 10, 190);
}
form button,
button {
	font: inherit;
	margin: 10px;
	background-color: #f0f0f0;
	border: 0;
	color: #242424;
	border-radius: 10px;
	font-size: 1em;
	padding: 0.375em 1em;
	font-weight: 600;
	text-shadow: 0 0.0625em 0 #fff;
	box-shadow: inset 0 0.0625em 0 0 #f4f4f4, 0 0.0625em 0 0 #efefef, 0 0.125em 0 0 #ececec, 0 0.25em 0 0 #e0e0e0, 0 0.3125em 0 0 #dedede, 0 0.375em 0 0 #dcdcdc, 0 0.425em 0 0 #cacaca, 0 0.425em 0.5em 0 #cecece;
	transition: 0.15s ease;
	cursor: pointer;
}
button:active {
	translate: 0 0.225em;
	box-shadow: inset 0 0.03em 0 0 #f4f4f4, 0 0.03em 0 0 #efefef, 0 0.0625em 0 0 #ececec, 0 0.125em 0 0 #e0e0e0, 0 0.125em 0 0 #dedede, 0 0.2em 0 0 #dcdcdc, 0 0.225em 0 0 #cacaca, 0 0.225em 0.375em 0 #cecece;
}
form label, 
form input, 
form select  {
    display: block;
}
form input{
	padding: 5px;
	margin-bottom: 10px;
	width: 95%;
	height: 30px;
	font-size: 1.4em;
	border-radius: 10px;
	font: inherit;
}
form select,
form textarea  {
    padding: 5px;
    margin-bottom: 10px;
    width: 95%;
    height: 30px;
    font-size: 1.4em;
	border-radius: 10px;
	font: inherit;
}
form textarea {
	width: 90%;
	padding: 10px;
	font-size: 1.2em;
	min-height: 50px;
}
form select {
    width: 262px;
}
input[type="date"]
{
    display:block;
  
    /* Solution 1 */
    /* -webkit-appearance: textfield; */
    /* -moz-appearance: textfield; */
    min-height: 1.2em; 
  
    /* Solution 2 */
    /* min-width: 96%; */
}
/* ICONS */
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 2.2rem;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
	text-shadow: 0 0.03em 1px #242424;
	transition: 0.15s ease;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}
.material-icons:hover{
	cursor: pointer;
}
.material-icons:active {
	text-shadow: 0 .0225em 1px #242424;
	translate: 0 2px;
	transform: scale(0.9);
	cursor: pointer;
}
.icons {
	display: block;
	margin-top: 3px;
    text-align: center;
    padding: 0;
	width: 100%;
	display: flex;
	flex-flow: nowrap;
	justify-content: center;
}
.icons .icon  {
	color: #eee;
	cursor: pointer;
	padding: 0 3px;
	/* display: block; */
	/* width: 100%;
	height: 100%; */
	/* transform-origin: center; */
	/* transform-origin: 0 100%; */
	transition: .3s;

}
.icons .icon:last-child {
	margin-right: 0;	
}
.icon.iconPlaylist { color: #86a800 }
.icon.iconCollect { color: #235d8e }
.icon.iconDelete { color: #9f5018 }
.icon.iconEdit { color: #9e6f6f }
.icon.iconFavorite { color: #9e031d }
.icon.iconInfo { color: #235d8e }
.icon.iconInfo:hover { color: #a83500 }
.icon.iconMore .material-icons{ 
	color: #7f589a;
	transform: rotate(0deg);
}
.icon.iconMoreOpen .material-icons { 
	color: #6e3296;
	transform: rotate(180deg); 
}
.icon.iconPlay { color: #f0233b /* The Apple Music Color */}
.icon.iconReview { color: #508944 }
.resetIcon {
	color: #501c1c;
	font-size: 2em;
	margin-top: 4px;
	transition: 1s;
}
.resetIcon:hover {
	color: #2d2d2d;
}
/* Tooltip */
.tooltip {
	position: relative;
	display: inline-block;
}
.tooltip .tooltiptext:hover {
	opacity: 1;
	transition: opacity .6s;
}
/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: rgba(45, 45, 45, 0.8);
	color: #eee;
	text-align: center;
	padding: 5px;
	border-radius: 6px;
	/* Position the tooltip text */
	position: absolute;
	z-index: 99;
	bottom: 125%;
	left: -39px;
	/* margin-left: -40px; */
	/* Fade in tooltip */
	opacity: 0;
	transition: opacity cubic-bezier(0.23, 1, 0.320, 1);
}
/* Tooltip arrow */
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
	transition: opacity 1s;
}
/* STAR RATING */
.star-text {
	font-size: 1.2em;
	font-weight: 900;
}
.avg-star-text {
	font-size: 1.6em;
	font-weight: 900;
}
.avgReview {
	justify-content: left;
}
.subheading {
	cursor: pointer;
	margin: 5px auto 10px;
	font-size: 1.6rem;
	transition: all 2s;
	text-shadow: 2px 2px 4px #121212;
	transition: all 1s;
}
.subheading:hover {
	text-shadow: 2px 2px 1px #121212;
}
.subheading h1 {
	font-size: 1.8em;
}
.subheading h2 {
	font-size: 1.6em;
}
.subheading h3 {
	font-size: 1.4em;
}
.subcontent {
	font-size: 1.4em;
}
.subcontent p, 
.subcontent h2,
.subcontent h3 {
	margin-bottom: 10px;
}
.subcontent p {
	line-height: 1.2em;
}
/* MODAL STYLING */
.modal {
	text-align: center;
	position: relative;
	background: #2229;
	color: #ddd;
	backdrop-filter: blur(5px);
	/* box-shadow: 2px 2px 20px 2px #888; */
	/* box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.6) inset, -4px -4px 4px #dddddd71; */
	min-height: 50vh;
	max-height: 85vh;
	/* min-width: 50vw; */
	min-width: 300px;
	max-width: 500px;
	margin-left: 10px;
	margin: auto auto;
	padding: 10px;
	border-radius: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 1s;
}
.modal:hover {
	transition: all 1s;
}
.modal-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	background-color: #000000aa;
	z-index: 99;
	padding: 10px;
	backdrop-filter: grayscale(100%)
}
.modal-header,
.modal-footer {
	margin-top: 20px;
}
.modal-header {
	display: block;
	padding: 10px;
	text-align: left;
	text-shadow: 5px 5px 3px #121212;
	color: #ae774a;
	justify-content: space-between;
	font-size: 1.2em;
	width: 90%;
	transition: all 1s;
}
.modal-header:hover {
	text-shadow: 2px 2px 1px #121212;
}
.modal-picture{
	width: 100%;
	display: block;
	margin: 0 auto;
	position: relative;
}
.modal-picture img{
	width: 100%;
}
.modal-body {
	position: relative;
	padding: 5px 10px;
	text-align: left;
}
.modal-body h2, 
.modal-body h3 {
	text-align: center;
}
.modal-body h2 {
	margin-top: 30px;
	margin-bottom: 10px;
}
.modal-footer {
	flex-direction: column;
	font-style: italic;
}
.modal .close-btn{
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	/* filter: invert(0); */
	border: none;
	cursor: pointer;
	color: rgba(240, 240, 240, 0.5);
	font-weight: 700;
	font-size: 40px;
	width: 40px;
	height: 40px;
	transform-origin: 50% 50%;
	transition: all 0.8s ease-out;
}
.modal .close-btn:hover{
	color: rgba(120, 120, 120, 0.7);
	transform: rotate(360deg);
}
/* SUNES */
.musicians ul li  {
	list-style-type: none;
	padding: 5px;
}
.musicians span  {
    cursor: pointer;
    font-weight: 700;
}
.readMore {
	cursor: pointer;
}
.shadowLow {
	--shadow-color: 219deg 36% 57%;
	box-shadow: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
}
.shadowMedium {
	--shadow-color: 219deg 36% 57%;
	box-shadow: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
}
.shadowHigh {
	--shadow-color: 219deg 36% 57%;
	box-shadow: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
	1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
	2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
	4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
	7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
	11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
	17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
	25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);
}
.fade-enter-active {
  transition: all 1s ease-in;
}
.fade-leave-active {
	transition: all 1.5s ease;
}
.fade-enter-to,
.fade-leave-from{
	opacity: 1;
}
.fade-enter-active .modal{
	animation: bounce-in 1s;
}
.fade-leave-active .modal{
	animation: bounce-in 10.5s;
}
.fade-enter-from,
.fade-leave-to{
	opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>