<template>
	<h1>Artists</h1>
	<header>
		<div class="alpha-buttons">
			<template v-for="letter in alpha" :key="letter">
				<div @click="getArtistsByLetter($event)" :class="{ letter: true, active: startLetter == letter }">{{ letter }}</div>
			</template>
			<h3>{{ hitsCount }} Hits</h3>
		</div>
		<!-- Searchbox -->
		<input v-model="searchQuery" @keyup="searchArtists" placeholder="Search artist" />
	</header>
	<div class="artistsContainer">
		<div v-for="artist in searchHits" :key="artist.id" class="artistBox">
			<div class="artistHeader">
				<h2 v-if="artist.alias">{{ artist.alias }}</h2>
			</div>
			<router-link :to="{ name: 'Artist', params: { id: artist.id } }">
				<div class="profileImage">
					<img :src="require('../assets/profiles/' + artist.id + '.jpg')" 
					:alt="artist.name" loading="lazy"
					class="bilde"
					>
				</div>
			</router-link>
			<h3 v-if="artist.alias">
				<span id="timespan" v-if="artist.birth">{{ timestampToYear(artist.birth) }}</span>
				<span v-if="artist.death"> - {{ timestampToYear(artist.death) }}</span>
			</h3>		
		</div>
	</div>
	<footer>
		<div class="page-buttons" v-if="pages.includes(1)">
			<!-- <h2>Footer</h2> -->
			<template v-for="page in pages" :key="page">
				<div @click="getArtistsByPage($event)" :class="{ page: true, active: pageNumber == page }">{{ page }}</div>
			</template>
		</div>
	</footer>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import { timestampToLongdate, timestampToTotalYears, timestampToYear } from '@/composables/timestampConverter';
import Artist from '@/views/Artist.vue';
// Firebase import
import { db } from '@/firebase/config';
import { collection, getCountFromServer, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { toTitleCase, getNextString } from '@/composables/stringConverter';

export default {
  components: { Artist },
  props: ['id', 'user'],
  emits: ['update-user','store-data'],
  setup(props, context) {
		// Setup
		const activeArtist = ref(null)
		const artists = ref([])
		const artistStore = ref([])
		
		//Alpha Nav
		const alpha = ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'])
		const startLetter = ref('A')
		const stopLetter = ref('B')
		
		//GUI
		const readMore = ref(false)
		
		// Page NAV
		const hitsCount = ref(0) // oppdateres ved getArtistsByLetter()
		const pages = ref([0]) // setupFooter
		const pageNumber = ref(1)
		const alphaNames = ref()
		const fetchedNames = ref([])
		const nextArtist = ref()
		const queryPos = ref(0)
		const alphaStart = ref(0)


		// Query refs
		const activeSorting = ref('name')
		const limiter = ref(12)
		
		// Search Engine
		const searchQuery = ref('');
		const searchHits = ref([])

		// Injects
		const artistConfig = inject('artistConfig', 'artistConfig')

		const searchArtists = async () => {
			searchHits.value = [] // Clear if search query is empty
			console.log('%c searchQuery', 'color:rgb(198, 218, 16);font-size:1.2em', searchQuery.value)
			// Normalize the search query to Title Case
			const normalizedQuery = toTitleCase(searchQuery.value)
			  // Check if there's any query to search
			// if (normalizedQuery.length === 0) {
			// 	searchHits.value = [] // Clear if search query is empty
			// 	return
			// }
			  // Get the next string for upper bound comparison
  			const nextString = getNextString(normalizedQuery);

			// Firestore query
			console.log('%c normalizedQuery', 'color:rgb(205, 66, 0);font-size:1.2em', normalizedQuery)
			console.log('%c nextString', 'color:rgb(62, 82, 173);font-size:1.2em', nextString)
			const artistRef = collection(db, 'artist')
			const q1 = ['name','>=', normalizedQuery]
			const q2 = ['name','<', nextString]
			const queryRef = query(artistRef, 
				where(...q1),
				where(...q2),
				orderBy(activeSorting.value),
				limit(limiter.value)
			)
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach((doc) => {
				searchHits.value.push({...doc.data(), id: doc.id})
				artistStore.value.push({...doc.data(), id: doc.id})
			});
			console.log('%c searchHits', 'color:rgb(160, 172, 244);font-size:1.2em', searchHits)
			
		}

		const getArtists = async (queryName) => { 
			let start
			queryName ? start = queryName : start = ''
			const colRef = collection(db, 'artist')
			const filter = [
				where('name','>=', startLetter.value),
				where('name','<=', stopLetter.value)
			]
			const queryRef = query(colRef, 
				...filter,
				orderBy(activeSorting.value),
				startAt(start),
				limit(limiter.value)
			)
			if(!queryName){
				// Tell opp første gang / eller ved klikk på bokstav
				const queryCount = query(colRef, ...filter)
				const total = await getCountFromServer(queryCount)
				hitsCount.value = total.data().count
			}
			
			let testArray = []
			// Fetch docs from firestore
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach((doc) => {
				searchHits.value.push({...doc.data(), id: doc.id})
				artistStore.value.push({...doc.data(), id: doc.id})
				fetchedNames.value.push(doc.data().name)
				testArray.push(doc.data().name)
			});
			nextArtist.value = querySnapshot.docs[0].data().name
			// nextArtist.value = querySnapshot.docs[0].data().name
			console.log('%c testArray', 'color:rgb(158, 102, 176);font-size:1.2em', testArray)
			console.log('%c searchHits', 'color:rgb(50, 6, 206);font-size:1.2em', searchHits.value)
			// Emit all fetched data from artist to App.vue
			context.emit('store-data', artistStore.value)
			// Log to console			
			console.log('%c fetchedNames (fetched names: alpha start)', 'color:rgb(206, 211, 109);font-size:1.2em', fetchedNames.value.length, [...fetchedNames.value])
			console.log('%c updated artistStore', 'color:rgb(107, 192, 140);font-size:1.2em', artistStore.value.length, [...artistStore.value])
			// Kun første gang
			if(!queryName){
				// nextArtist.value = querySnapshot.docs[0].data().name
				setupFooter()
			}			
		}

		const setupFooter = () => {
			console.log('%c setupFooter', 'color:rgb(42, 191, 86);font-size:1.7em', nextArtist.value)
			pages.value = [1]
			// Function to update pagination footer
			const calculate = hitsCount.value / searchHits.value.length
			console.log('%c calculate', 'color:rgb(43, 216, 39);font-size:1.2em', calculate)
			if(calculate > 1) {
				const calculateLetters =  Math.ceil(calculate)
				// Update pages array, to be displayed in the pagination footer
				for (let i = 2; i <= calculateLetters; i++) {
					pages.value.push(i)
				}
				// Create array for alpha musikere
				let musicians = artistConfig.value.artistNames
				console.log('%c nextArtist.value', 'color:rgb(16, 89, 38);font-size:1.2em', nextArtist.value)
				const pos = musicians.indexOf(nextArtist.value)

				console.log('%c Setup pos', 'color:rgb(131, 155, 8);font-size:1.8em', pos, hitsCount.value, limiter.value)
				console.log('start', pos)
				console.log('hitsCount', hitsCount.value)
				console.log('limiter',limiter.value)
				console.log('stop', pos + hitsCount.value)
				alphaStart.value = pos
				console.log('%c setupFooter updated alphaStart value', 'color:red;font-size:1.4em', alphaStart.value)
				
				// Slice 
				console.log('%c setupFooter updated queryPos', 'color:pink;font-size:1.4em', queryPos.value)
				const namesSlice = musicians.slice(pos, pos + hitsCount.value)
				console.log('%c namesSlice', 'color:rgb(194, 12, 173);font-size:1.2em', namesSlice)
				alphaNames.value = namesSlice
				const pageSlice = musicians.slice(pos, pos + limiter.value)
				fetchedNames.value = pageSlice
				// Log to console
				console.log('%c alphaNames (all names on letter:)', 'color:rgb(206, 211, 109);font-size:1.2em', startLetter.value, alphaNames.value.length, [...alphaNames.value])
				console.log('%c fetchedNames (pageSlice in setupFooter) (fetched names: alpha start)', 'color:rgb(206, 211, 109);font-size:1.2em', fetchedNames.value.length, [...fetchedNames.value])

			}
		}

		const getArtistsByPage = (evt) => {
			// Reset hits array
			searchHits.value = []
			// hitsCount.value = 0
			// Calc click
			const clickNumber = Number(evt.target.innerText)
			pageNumber.value = clickNumber

			let musicians = artistConfig.value.artistNames
			const pos = musicians.indexOf(nextArtist.value)

			const posCalc = (clickNumber-1) * limiter.value
			// queryPos.value = posCalc
			console.log('%c getArtistsByPage: queryPos, pos, posCalc', 'color:lime;font-size:1.6em', queryPos.value, pos, posCalc)
			const queryName = alphaNames.value[posCalc]

			console.log('%c pageNumber', 'color:rgb(203, 151, 96);font-size:1.2em', pageNumber.value)
			console.log('%c posCalc', 'color:rgb(88, 167, 89);font-size:1.9em', posCalc)
			console.log('%c queryPos', 'color:rgb(245, 24, 249);font-size:1.2em', queryPos.value)
			console.log('%c limiter', 'color:rgb(159, 126, 177);font-size:1.2em', limiter.value)
			console.log('%c hitsCount', 'color:rgb(167, 33, 239);font-size:1.2em', hitsCount.value)
			console.log('%c calc pos-stop-end', 'color:rgb(88, 167, 89);font-size:1.9em', queryPos.value + limiter.value, posCalc + limiter.value, hitsCount.value)
			
			
			
			// const pos = fetchedNames.value.indexOf(queryName)
			// queryPos.value = pos
			// console.log('%c pos', 'color:rgb(77, 52, 85);font-size:1.2em', pos)
			nextArtist.value = queryName
			// Check if the values ​​exist, before calling for new new data
			getArtists(queryName)
			// const artistExists = artistStore.value.some(artist => artist.name === queryName)
			// if (!artistExists){
			// 	console.log('%c Artist !exists in artistStore, fetch firestore data', 'color:rgb(195, 50, 98);font-size:1.4em')
			// 	getArtists(queryName)
			// } else {
			// 	console.log('%c Artist exists in storage, use artistStore', 'color:rgb(50, 195, 98);font-size:1.4em', artistStore.value)
			// 	// Update hits with data from artistStore
			// 	// let pos = queryPos.value
			// 	// let limit = pos + limiter.value
			// 	let result = []
			// 	// for (pos; pos < limit; pos++) {
			// 	// 	let element = artistStore.value[pos];
			// 	// 	result.push({...element})
			// 	// 	searchHits.value.push(element)
			// 	// 	console.log('%c element', 'color:rgb(67, 81, 69);font-size:1.8em', element)
			// 	// }
			// 	console.log('alphaNames: ', alphaNames.value)
			// 	for (let i = 0; i < artistStore.value.length; i++) {
			// 		const elementName = artistStore.value[i].name;
			// 		console.log('element: ', elementName, i)
			// 		result.push(elementName)

			// 		// if(element.name == alphaNames)
					
			// 	}
			// 	console.log('%c result', 'color:rgb(53, 239, 29);font-size:1.6em', result)






			// 	// console.log('%c queryPos.value, queryPos.value + limiter.value', 'color:rgb(49, 107, 24);font-size:1.2em', queryPos.value, limiter.value)
			// 	// console.log('%c queryPos.value + limiter.value', 'color:rgb(54, 71, 56);font-size:1.2em', queryPos.value + limiter.value)
			// 	// let hits = artistStore.value.slice(queryPos.value, queryPos.value + limiter.value)
			// 	// console.log('%c hits', 'color:rgb(105, 101, 128);font-size:1.6em', hits)
			// 	// searchHits.value = hits
			// }
		}

		const getArtistsByLetter = (evt) => {
			console.log('%c alphaStart', 'color:rgb(231, 199, 0);font-size:1.2em', alphaStart.value)
			console.log('%c getArtistsByLetter: queryPos, pos, posCalc', 'color:lime;font-size:1.6em', queryPos.value)
			searchHits.value = []
			pageNumber.value = 1
			// pages.value = []
			const clickLetter = evt.target.textContent
			startLetter.value = clickLetter
			const index = alpha.value.indexOf(clickLetter)
			// console.log('index: ', index)
			const nextLetter = alpha.value[index+1]
			if(clickLetter != 'Z'){
				stopLetter.value = nextLetter
			} else {
				stopLetter.value = 'z'
			}
			getArtists()
		}

		const clickImage = (artist, evt) => {
			console.log('clickImage', activeArtist.value)
			activeArtist.value = artist
		}

		const changeSort = (evt) => {
			let sort = evt.target.value
			console.log('sort', sort)
			activeSorting.value = sort
			console.log('activeSorting: ', activeSorting.value)
		}
		


		onMounted(() => {
			getArtists()
		})

		return { artists, timestampToLongdate, timestampToYear, clickImage, readMore, timestampToTotalYears,
			activeArtist, activeSorting, changeSort, alpha, startLetter, getArtistsByLetter, stopLetter, 
			getArtists, limiter,
			searchQuery, searchArtists, searchHits, toTitleCase, getNextString, pages, hitsCount, getArtistsByPage, 
			alphaNames, artistStore, setupFooter, pageNumber, fetchedNames, nextArtist, queryPos
		}
	}

}
</script>

<style scoped>
input{
	width: 80vw;
	height: 40px;
	display: block;
	padding: 5px;
	margin: 20px auto;
	font-size: 1.2em;
	font-weight: 500;
}

footer {
	background: #58585845;
}
.artistsContainer{
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 10px;
}
.artistBox {
	background: #58585845;
	padding: 10px;
	border-radius: 10px;
	margin: 20px auto;
	max-width: 400px;
	min-width: 180px;
	flex: 0 1 12%;
	flex-grow: 1;
}
.artistHeader h2{
	margin: 0 auto 20px;
	text-align: center;
}
.artistBox h3{
	margin: 10px auto 5px;
	font-size: 1.2rem;
	text-align: center;
}
.profileImage img{
	width: 100%;
	display: block;
	border-radius: 50%;
	--crop-focus-x: 0.6;
	--crop-focus-y: 0.1;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: calc(var(--crop-focus-x) * 100%) calc(var(--crop-focus-y) * 100%);
	margin: 0 auto;
}
.alpha-buttons,
.page-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
	align-content: space-between;
	gap: 5px;
	/* cursor: pointer; */
}
.letter,
.page {
	font-size: 1em;
	line-height: 1em;
	background: #cccccccc;
	color: #181818;
	display: block;
	padding: 5px;
	height: 30px;
	width: 30px;
	text-align: center;
	transition: all 0.5s;
}
.letter:hover,
.page:hover {
	font-weight: 700;
	background: #ddd;
	cursor: pointer;
}
.letter.active,
.page.active {
	font-size: 1.2em;
	padding-top: 4px;
	font-weight: 900;
	background: #d8afaf;
}
.controls{
	width: fit-content;
	margin: 0 auto;
}
.custom-select {
	max-width: 350px;
	margin: 0 auto;
	position: relative;
}
.custom-select select {
	appearance: none;
	width: 100%;
	font-size: 1.15rem;
	padding: 0.675em 6em 0.675em 1em;
	cursor: pointer;
	background-color: var(--dropdown-background);
	border-radius: var(--dropdown-border-radius);
	color: var(--dropdown-text-color);
}
.custom-select::before,
.custom-select::after {
	--size: 0.3rem;
	position: absolute;
	content: "";
	right: 1rem;
	pointer-events: none;
}
.custom-select::before {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-bottom: var(--size) solid black;
	top: 40%;
}
.custom-select::after {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-top: var(--size) solid black;
	top: 55%;
}
.custom-select select:hover {
	background-color: var(--dropdown-hover-color);
}

.custom-select::before {
/* ... */
	border-bottom: var(--size) solid var(--dropdown-icon-color)
}

.custom-select::after {
/* ... */
	border-top: var(--size) solid var(--dropdown-icon-color);
}
:root {
	/* light theme */
	--dropdown-background: #fff;
	--dropdown-text-color: #111;
	--dropdown-hover-color: #eee;
	--dropdown-border-color: #181818;
	--dropdown-border-radius: 0.25em;
	--dropdown-icon-color: #111;
	--background-color: #eee;
	--text-color: #111;
}
/* dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--dropdown-background: #111;
		--dropdown-text-color: #fff;
		--dropdown-hover-color: #222;
		--dropdown-border-color: #555;
		--dropdown-border-radius: 0.25em;
		--dropdown-icon-color: #fff;
		--background-color: #111;
		--text-color: #fff;
	}
}
</style>