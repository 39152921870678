<template>
	<header>
		<div class="navButtons">
			<button id="prev" @click="changeArtist" v-text="prevArtist"></button>
			<button id="next" @click="changeArtist" v-text="nextArtist"></button>
		</div>
	</header>
	<div v-if="artist" class="artistContainer" >
		<ArtistImage
		v-if="artist"
		class="artistImage"
		:artist="artist"
		:activeID="activeID"
		/>
		<div class="title">
			<h2 v-if="artist.alias">{{ artist.alias }}</h2>
			<h3>
				<span id="timespan" v-if="artist.birth">{{ timestampToYear(artist.birth) }}</span>
				<span v-if="artist.death"> - {{ timestampToYear(artist.death) }}</span>
			</h3>
		</div>
		<div class="panel">
			<h3 @click="showArtistInfo = !showArtistInfo" class="subheading">{{ artist.alias }} Info</h3>
			<ArtistInfo
				v-if="showArtistInfo"
				class="artistInfo"
				:artist="artist"
				:activeID="activeID"
				:user="user"
			/>
		</div>
		<div class="panel">
			<h3 @click="showArtistBio = !showArtistBio" class="subheading">{{ artist.alias }} Biography</h3>
			<ArtistBio
				v-if="showArtistBio"
				class="artistBio"
				:artist="artist"
				:activeID="activeID"
			/>
		</div>
		<div v-if="artistAlbums.length != 0" class="panel">
			<h3 @click="showArtistAlbums = !showArtistAlbums" class="subheading">{{ artist.alias }} has {{ artistAlbums.length }} albums</h3>
			<ArtistAlbums
				v-if="showArtistAlbums"
				class="albumArtists"
				:artistAlbums="artistAlbums"
			/>
		</div>
		<div v-if="musicianAlbums.length != 0" class="panel">
			<h3 @click="showMusicianAlbums = !showMusicianAlbums" class="subheading">{{ artist.alias }} is playing on {{ musicianAlbums.length }} albums</h3>
			<ArtistMusician
				v-if="showMusicianAlbums"
				class="artistMusicican"
				:artistAlbums="musicianAlbums"
			/>
		</div>
	</div>
</template>

<script>
import { makeArray } from '@/composables/makeArray';
import { timestampToLongdate, timestampToTotalYears, timestampToYear } from '@/composables/timestampConverter';
import { db } from '@/firebase/config';
import { collection, doc, getDoc, getDocs, or, orderBy, query, where } from 'firebase/firestore';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
// Modul Components
import ArtistAlbums from '@/components/artistModules/ArtistAlbums';
import ArtistBio from '@/components/artistModules/ArtistBio.vue';
import ArtistImage from '@/components/artistModules/ArtistImage.vue';
import ArtistInfo from '@/components/artistModules/ArtistInfo.vue';
import ArtistMusician from '@/components/artistModules/ArtistMusician';

export default {
	props: ['id', 'user'],
	emits: ['update-user','store-data'],
	components: { ArtistImage, ArtistInfo, ArtistBio, ArtistAlbums, ArtistMusician },
	setup(props) {
		const router = useRouter()
		const activeID = ref(props.id)
		const artist = ref(null) // Active artist
		// All Artists Arrays from config 
		const artistNames = ref([])
		const artistAlias = ref([])
		const artistIDs = ref([])

		const artistAlbums = ref([]) // Artist album discogs
		const musicianAlbums = ref([]) // Artist is a musician on this albums
		// Buttons
		const nextArtist = ref(null) // Next Button
		const prevArtist = ref(null) // Prev Button
		// Show / Hide Panels
		const showArtistInfo = ref(true)
		const showArtistBio = ref(true)
		const showArtistAlbums = ref(true)
		const showMusicianAlbums = ref(true)

		const updateButtons = async (name) => {
			const pos = artistIDs.value.indexOf(name)
			// Hvis første
			if(pos === 0){
				nextArtist.value = artistAlias.value[pos+1]
				prevArtist.value = artistAlias.value[artistAlias.value.length -1]
			}
			// Hvis siste
			else if(pos == artistAlias.value.length -1){
				nextArtist.value = artistAlias.value[0]
				prevArtist.value = artistAlias.value[pos-1]
			}
			else {
				nextArtist.value = artistAlias.value[pos+1]
				prevArtist.value = artistAlias.value[pos-1]
			}
			console.log('updateButtons => next', nextArtist.value, 'prev', prevArtist.value)
		}

		const changeArtist = (evt) => {
			let targetId = evt.target.id
			let pos = artistNames.value.indexOf(artist.value.name)
			if(targetId === 'next'){
				if(pos != artistNames.value.length -1){
					let next = artistIDs.value[pos+1]
					nextArtist.value = artistNames.value[pos+1]
					prevArtist.value = artistNames.value[pos-1]
					getArtist(next)
					router.push(`/artist/${next}`) 
				} else { // If last artist, go to first
					let next = artistIDs.value[0]
					nextArtist.value = artistNames.value[0]
					prevArtist.value = artistNames.value[pos]
					getArtist(next)
					router.push(`/artist/${next}`) 
				}
			} else {
				if(pos != 0){
					let prev = artistIDs.value[pos-1]
					getArtist(prev)
					router.push(`/artist/${prev}`)
				} else { // If first artist, go to last
					let prev = artistIDs.value[artistNames.value.length -1]
					getArtist(prev)
					router.push(`/artist/${prev}`)
				}
			}
		}

		const getArtist = async (id) => { 
			console.log('getArtist', id)
			const artistRef = doc(db, 'artist', id)
			const artistSnap = await getDoc(artistRef)
			if (artistSnap.exists()){
				artist.value = {...artistSnap.data(), id: artistSnap.id}
				console.log('active artist: ', artist.value)
				getMusicianAlbums(artistSnap.data().alias)
			}
		}

		const getAllMusicians = async (currentId) => {
			const docRef = doc(db, 'config', "allArtists")
			const docSnap = await getDoc(docRef)
			if (docSnap.exists()){
				artistNames.value = docSnap.data().artistName
				artistAlias.value = docSnap.data().artistAlias
				artistIDs.value = docSnap.data().artistID
			}
			let pos = artistNames.value.indexOf(currentId)
			nextArtist.value = artistIDs.value[pos+1]
			prevArtist.value = artistIDs.value[pos-1]
		}

		const getMusicianAlbums = async (name) => {
			if (name != artist.value){
				artistAlbums.value = []
				musicianAlbums.value = []
				const colRef = collection(db, 'Jazz')
				let queryRef = query(colRef,
					or(
						where('Artist','==', name),
						where('musicianBassArr','array-contains-any', [name]),
						where('musicianClarinetArr','array-contains-any', [name]),
						where('musicianCongaArr','array-contains-any', [name]),
						where('musicianCornetteArr','array-contains-any', [name]),
						where('musicianDrumsArr','array-contains-any', [name]),
						where('musicianEuphoniumArr','array-contains-any', [name]),
						where('musicianFluteArr','array-contains-any', [name]),
						where('musicianGuitarArr','array-contains-any', [name]),
						where('musicianHarpArr','array-contains-any', [name]),
						where('musicianHornsArr','array-contains-any', [name]),
						where('musicianOrganArr','array-contains-any', [name]),
						where('musicianPercussionArr','array-contains-any', [name]),
						where('musicianPianoArr','array-contains-any', [name]),
						where('musicianSaxAltoArr','array-contains-any', [name]),
						where('musicianSaxBarytonArr','array-contains-any', [name]),
						where('musicianSaxSopranoArr','array-contains-any', [name]),
						where('musicianSaxTenorArr','array-contains-any', [name]),
						where('musicianSitarArr','array-contains-any', [name]),
						where('musicianStringArr','array-contains-any', [name]),
						where('musicianTromboneArr','array-contains-any', [name]),
						where('musicianTrumpetArr','array-contains-any', [name]),
						where('musicianTubaArr','array-contains-any', [name]),
						where('musicianVibraphoneArr','array-contains-any', [name]),
						where('musicianVocalArr','array-contains-any', [name]),
					),
					orderBy('Recorded1', 'asc'),
				)
				const querySnapshot = await getDocs(queryRef);
				querySnapshot.forEach((doc) => {
					let data = {id: doc.id, ...doc.data()}
					if(data.Artist.includes(name)){
						artistAlbums.value.push(data)
					} else {
						musicianAlbums.value.push(data)
					}
				})
				updateButtons(artist.value.id)
				console.log('artistAlbums: ', artistAlbums.value.length, artistAlbums)
				console.log('musicianAlbums: ', musicianAlbums.value.length, musicianAlbums)
			}
		}
		
		onMounted(() => {
			getArtist(props.id)
			getAllMusicians(props.id)
			// updateButtons() // Update Next / Prev buttons with artistnames
		})
		
		return {
			router, changeArtist, activeID,
			timestampToLongdate, timestampToYear, timestampToTotalYears, makeArray, 
			artist, getArtist, getAllMusicians,
			artistNames, artistIDs, artistAlias, updateButtons, nextArtist, prevArtist, 
			getMusicianAlbums, artistAlbums, musicianAlbums,
			showArtistInfo, showArtistBio, showArtistAlbums, showMusicianAlbums
		}
	}
}
</script>

<style scoped>
	.moduleContent{
		padding: 10px;
		margin: 10px;
	}
	.error {
		text-align: center;
	}
	.artistContainer h2,
	.artistContainer h3{
		text-align: center;
		text-shadow: 2px 1px 2px rgba(0,0,0,0.3);
	}
	.artistContainer h3{
		font-size: 1.4em;
	}
	.artistImage{
		margin-top: 20px;
	}
	.title{
		font-size: 1.1em;
		padding: 20px;
		display: block;
		margin: 0 auto;
		width: 100%;
	}
	.title h3 {
		font-size: 1em;
	}
	header.navButtons,
	footer.navButtons{
		margin: 10px
	}
	header{
		max-width: 960px;
		margin: 0 auto;
	}
	footer{
		width: 100%;
		padding: 10px;
	}
	/* Float buttons */
	button{
		font-size: 0.9em;
		font-weight: 800;
	}
	#prev{
		float: left;
	}
	#next{
		float: right;
	}
	.subheading{
		cursor: pointer;
		margin-top: 10px;
		font-size: 1.8em;
		transition: all 2s;
		text-shadow: 5px 5px 3px #121212;
		transition: all 1s;
	}
</style>