<template>
	<AlbumModule
		v-if="album"
		:user="user"
		:album="album"
		:reviews="reviews"
		:musicians="musicians"
		:artistIDs="artistIDs"
		:userReview="userReview"
		:artistAlias="artistAlias"
		:artistNames="artistNames"
		:artistAlbums="artistAlbums"
		:userCollected="userCollected"
		:musicianAlbums="musicianAlbums"
		@new-album="(alb) => getAlbum(alb.id)"

		@new-review ="(data, user) => newReview(data, user)"
		@update-review ="(data, user) => updateReview(data, user)"
		@delete-review ="(data, user) => deleteReview(data, user)"
		@new-collected ="(data, user) => newCollected(data, user)"
		@update-collected ="(data, user) => updateCollected(data, user)"
		@delete-collected ="(data, user) => deleteCollected(data, user)"	
	/>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
// Firebase import
import { auth, db } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, or, orderBy, query, where } from 'firebase/firestore'

import { groupMusiciansByInstruments } from '@/composables/arrayToMusicians'
import { getMusicians } from '@/composables/getMusicians'
import { fetchArtistAlbums } from '@/composables/fetchArtistAlbums'
import AlbumModule from '@/views/AlbumView.vue'
import { formatDistanceToNow } from 'date-fns'

export default {
	props: ['id', 'user'],
	emits: ['update-user','store-data'],
	components: { AlbumModule },
    setup(props, context){
		const authUser = ref(auth.currentUser)
		const userCollected = ref(null)
        const router = useRouter()
		const album = ref(null)
		const userReview = ref(null)
		
		
		const artistNames = ref([])  //All artist names in db
		const artistAlias = ref([]) ///All artist aliases in db
		const artistIDs = ref([]) //All artist IDs in db
		const artistAlbums = ref([]) // Artist album discogs
		const musicianAlbums = ref([]) // Artist is a musician on this albums
		const reviews = ref([]) // Album reviews
		const albumReviews = ref([]) // Album reviews
		const activeArtist = ref(null)
		const musicians = ref([]) // Album musicians

		const newCollected = (data, user) => {
			console.log('%c newCollected', 'color:green;font-size:1.2em', data, user)
			let timeCreated
			if(data.created){ timeCreated = formatDistanceToNow(data.created.toDate()) }
			userCollected.value.timeCreated = timeCreated
			console.log('%c New Collected Album ', 'color:dodgerblue', data, user)
	
			userCollected.value = data
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const newReview = (data, user) => {
			console.log('%c New Reviewed Album', 'color:green;font-size:1.2em', data, user)
			let timeCreated
			if(data.created){ timeCreated = formatDistanceToNow(data.created.toDate()) }
			// Update GUI
			userCollected.value.timeCreated = timeCreated
			reviews.value.push(data)
			userReview.value = data
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const deleteCollected = (data, user) => {
			userCollected.value = {}
			console.log('%c Album Deleted from Collection ', 'color:red;font-size:1.2em', data, user)
			// Update global user: Emit collected to masterparent (App.vue)
			context.emit('update-user', user)
		}
		const deleteReview = (data, user) => {
			userReview.value = {}
			console.log('%c Review Deleted ', 'color:red;font-size:1.2em', data, user)
			context.emit('update-user', user)
		}
		const updateCollected = (data, user) => {
			console.log('%c Updated Collected Album ', 'color:orange;font-size:1.2em', data, user)
			let timeUpdated
			if(data.updated){ timeUpdated = formatDistanceToNow(data.updated.toDate()) }
			userCollected.value.timeUpdated = timeUpdated
			context.emit('update-user', user)
		}
		const updateReview = (data, user) => {
		console.log('%c Updated Reviewed Album', 'color:orange;font-size:1.2em', data, user)
			let timeUpdated
			if(data.updated){ timeUpdated = formatDistanceToNow(data.updated.toDate()) }
			userReview.value.timeUpdated = timeUpdated
			const result = reviews.value.map(( review ) => { 
				if(review.userID == props.user.id){
					review.timeUpdated = timeUpdated
					// Update userReview with new data
					userReview.value = review
					console.log('%c Updated User Review ', 'color:gold', userReview.value)
					context.emit('update-user', user)

				}
			});
		}

		const getCollectedAlbum = async (albumId) => {
			console.log('getCollectedAlbum!!')
			if(props.user.id != null){
				console.log('%c getCollectedAlbum', 'color: yellow', albumId, props.user.id)
				const collectedRef = doc(db, 'users', props.user.id, "collection", albumId)
				const collectDoc = await getDoc(collectedRef)
				if (collectDoc.exists()){
					// If user is defined
					if(props.user !== null) {
						// If user has a collected album
						console.log('%c User has this album in his collection', 'color: lime', albumId, props.user.id)
						let timeCreated, timeUpdated
						if(collectDoc.data().created){ timeCreated = formatDistanceToNow(collectDoc.data().created.toDate()) }
						if(collectDoc.data().updated){ timeUpdated = formatDistanceToNow(collectDoc.data().updated.toDate()) }
						// Update userCollected array
						userCollected.value = {
							...collectDoc.data(), 
							id: collectDoc.id, 
							timeCreated: timeCreated,
							timeUpdated: timeUpdated
						}
					// No active user
					} else {
						console.log('%c No active user!', 'color: red')
						userCollected.value = {}
					}
				// Not in user collection => reset userCollected
				} else {
					console.log('%c Not found in user collection!', 'color: red')
					userCollected.value = {}
				}
			}
		}

		const getAlbum = async (id) => { 
			const albumRef = doc(db, 'Jazz', id)
			const albumSnap = await getDoc(albumRef)
			let timeCreated, timeUpdated
			if (albumSnap.exists()){
				if(albumSnap.data().created){timeCreated = formatDistanceToNow(albumSnap.data().created.toDate())}
				if(albumSnap.data().updated){timeUpdated = formatDistanceToNow(albumSnap.data().updated.toDate())}
				album.value = {
					id: albumSnap.id, 
					...albumSnap.data(), 
					timeCreated:timeCreated, 
					timeUpdated: timeUpdated 
				}
				// Henter alle albumer med artisten som musiker
				// getMusicianAlbums(albumSnap.data().Artist)

				if (albumSnap.data().Artist != activeArtist.value){	
					const { artistOnAlbums, musicianOnAlbums } = await fetchArtistAlbums(albumSnap.data().Artist)
					console.log('%c artistOnAlbums', 'color:rgb(101, 152, 1);font-size:1.2em', artistOnAlbums.length, artistOnAlbums)
					console.log('%c musicianOnAlbums', 'color:rgb(101, 152, 1);font-size:1.2em', musicianOnAlbums.length, musicianOnAlbums)
					artistAlbums.value = artistOnAlbums
					musicianAlbums.value = musicianOnAlbums
				}




				// Henter Album reviews
				getReviews(albumSnap.id)
				// Henter albumets musikere
				musicians.value = await getMusicians(album.value)
				console.log('musicians: ', musicians)
				// Henter albumet fra brukerens samling
				// getCollectedAlbum(albumSnap.id)
			}
		}

		// // Henter alle albumer med artisten som musiker
		// const getMusicianAlbums = async (name) => {
		// 	console.log('getMusicianAlbums', name)
		// 	if (name != activeArtist.value){
		// 		artistAlbums.value = []
		// 		musicianAlbums.value = []
		// 		const colRef = collection(db, 'Jazz')
		// 		let queryRef = query(colRef,
		// 			or(
		// 				// where('Artist','==', name),
		// 				where('artistArray','array-contains-any', [name]),
		// 				// where('Artist','<=', name + '\uf8ff'),
		// 				where('musicianBassArr','array-contains-any', [name]),
		// 				where('musicianClarinetArr','array-contains-any', [name]),
		// 				where('musicianCongaArr','array-contains-any', [name]),
		// 				where('musicianCornetteArr','array-contains-any', [name]),
		// 				where('musicianDrumsArr','array-contains-any', [name]),
		// 				where('musicianEuphoniumArr','array-contains-any', [name]),
		// 				where('musicianFluteArr','array-contains-any', [name]),
		// 				where('musicianGuitarArr','array-contains-any', [name]),
		// 				where('musicianHarpArr','array-contains-any', [name]),
		// 				where('musicianHornsArr','array-contains-any', [name]),
		// 				where('musicianOrganArr','array-contains-any', [name]),
		// 				where('musicianPercussionArr','array-contains-any', [name]),
		// 				where('musicianPianoArr','array-contains-any', [name]),
		// 				where('musicianSaxAltoArr','array-contains-any', [name]),
		// 				where('musicianSaxBarytonArr','array-contains-any', [name]),
		// 				where('musicianSaxSopranoArr','array-contains-any', [name]),
		// 				where('musicianSaxTenorArr','array-contains-any', [name]),
		// 				where('musicianSitarArr','array-contains-any', [name]),
		// 				where('musicianStringArr','array-contains-any', [name]),
		// 				where('musicianTromboneArr','array-contains-any', [name]),
		// 				where('musicianTrumpetArr','array-contains-any', [name]),
		// 				where('musicianTubaArr','array-contains-any', [name]),
		// 				where('musicianVibraphoneArr','array-contains-any', [name]),
		// 				where('musicianVocalArr','array-contains-any', [name]),
		// 			),
		// 			orderBy('Recorded1', 'asc'),
		// 		)
		// 		const querySnapshot = await getDocs(queryRef);
		// 		querySnapshot.forEach((doc) => {
		// 			let data = {id: doc.id, ...doc.data()}
		// 			if(data.Artist.includes(name) || data.Artist === name){
		// 				artistAlbums.value.push(data)
		// 			} else {
		// 				musicianAlbums.value.push(data)
		// 			}
		// 		})
		// 		// console.log('%c artistAlbums ', 'color:goldenrod', artistAlbums.value.length, artistAlbums.value)
		// 		// console.log('%c musicianAlbums ', 'color:gold', musicianAlbums.value.length, musicianAlbums.value)
		// 	}
		// }

		const getReviews = async (albumId) => {
			reviews.value = []
			albumReviews.value = []
			// const activeUserID = user.value.id
			const reviewRef = collection(db, 'reviews')
			let queryRef = query(reviewRef, 
					where('albumID','==', albumId),
					orderBy('created', 'asc')
				)
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach((review) => {
				let data = {...review.data(), reviewID: review.id}
				let reviewUser = review.data().userID
	
				if(review.data().created){
					data.timeCreated = formatDistanceToNow(review.data().created.toDate())
				}
				if(review.data().updated){
					data.timeUpdated = formatDistanceToNow(review.data().updated.toDate())
				}
				albumReviews.value.push(review.id)
				reviews.value.push(data)
		
				// If user is defined
				if(props.user !== null){
					// If user has a review
					if(props.user.id === reviewUser ){
						// console.log('%c User Review: ' + review.id, 'color: green')
						// User review => get data
						// console.log('%c User '+ user.value.id, 'color: lime' )
						// console.log('%c Review Id ' + review.id, 'color: green')
						userReview.value = data
					// No user review => reset userReviev
					} else {
						// console.log('%c No review found in user collection!', 'color: red', reviewUser)
						userReview.value = {}
					}
				} else {
					// No active user => reset userReviev
					console.log('%c No active user!', 'color: red')
					userReview.value = {}
				}
				})
				// console.log('%c reviews', 'color: green; background: #3333;', reviews, albumReviews )
		}
		// Get array of artists in db
		const getAllArtists = async () => {
			const docRef = doc(db, 'config', "allArtists")
			const docSnap = await getDoc(docRef)
			if (docSnap.exists()){
				artistNames.value = docSnap.data().artistName
				artistAlias.value = docSnap.data().artistAlias
				artistIDs.value = docSnap.data().artistID
			}
			// console.log('artistNames: ', artistNames)
			// console.log('artistIDs: ', artistIDs)
		}
		
		// Watch (wait) for update from App user, then =>
		const waitForUser = watch(()=> props.user, (fetchedUser) => {
			getCollectedAlbum(props.id)
        })
		
		onMounted(()=>{
			getAlbum(props.id)
			getAllArtists() // Get array of artists in db
			getCollectedAlbum(props.id)
			// getCollectedAlbum(props.id)
		})

        return { router, album, artistNames, artistAlias, artistIDs, artistAlbums, musicianAlbums,
			getAlbum, getAllArtists, activeArtist, getReviews, reviews,
			authUser, getCollectedAlbum, userCollected,
			getMusicians, groupMusiciansByInstruments, musicians, newReview, userReview, updateReview, albumReviews, deleteReview,
			newCollected, updateCollected, deleteCollected, fetchArtistAlbums
        }

    }

}
</script>